<template>
  <div>
    <div class="main-header" style="min-width:1540px">
      <div class="search-for"></div>
      <div class="nav-width">
        <div class="nav-frist">
          <div class="nav-top">
            <div class="nav-flex">
              <div>
                <el-image style="width: 6vw; height: 6vw"
                  src="https://oss.yanxin7.cn/2023-08-11/2921cd207da6499783f46f2fc3d0f1c2"></el-image>
              </div>
              <div class="flex-start">
                <div style="margin-bottom: 10px; font-weight: bold">
                  企业定位
                </div>
                <div class="c-8">聚焦建筑产业链-数字化解决方案服务商</div>
              </div>
            </div>
            <div class="nav-flex">
              <div>
                <el-image style="width: 6vw; height: 6vw"
                  src="https://oss.yanxin7.cn/2023-08-11/2921cd207da6499783f46f2fc3d0f1c2"></el-image>
              </div>
              <div class="flex-start">
                <div style="margin-bottom: 10px; font-weight: bold">
                  平台理念
                </div>
                <div class="c-8">开放式--数字建筑重塑价值生态系统</div>
              </div>
            </div>
          </div>
          <div class="flex-cloumn-center" ref="targetElement">
            <h1 class="company-name">公司介绍</h1>
            <div class="b-line"></div>
            <div class="flex-row-center" style="margin-bottom: 10vw">
              <div style="width: 38vw; margin-right: 2vw">
                <div class="text">
                  &nbsp; &nbsp; &nbsp;
                  &nbsp;邦建通科技集团股份有限公司总部于广西南宁，前身为言信物联网科技有限责任公司，成立于2019年8月，注册资本：伍仟万元。专精特新科技企业，公司拥有专业的技术研发团队40多人，联合创始成员10人，拟计划全国设立六大区域三十一家全资子公司，300多个分公司，2800多个办事处，集全国各地城市合伙人以股权与分红权激励进行合作。完善的管理体系与企业文化，创新的商业理念赢得终端用户的信任支持。
                </div>
                <div class="text">
                  &nbsp; &nbsp; &nbsp;
                  &nbsp;邦建通数字建筑平台目前已与全国各地公共资源交易中心、国家建筑市场监督、信用中国、工商照面、多个通信运营商以及启信宝等官方与商业系统API接入。全国建筑协会、金融机构、保险公司、全国建筑施工企业与大型制造业企业产生战略合作伙伴关系。
                </div>
                <div class="text">
                  &nbsp; &nbsp; &nbsp;
                  &nbsp;集团把科技创新放在发展大局的核心位置，坚持以推动建筑行业发展为己任，积极探索和打造建筑行业生态体系，构建了“移动招投标管理体系、数字化施工管理体系、供应链金融生态体系、农民工数字管理体系、建筑业智库服务体系”等建设工程领域全生命周期数字化管理服务系统，全面赋能建筑行业全过程在线化与数字化的行业领跑者。
                </div>
              </div>
              <div style="width: 22vw">
                <el-image style="width: 22vw; height: 23vw"
                  src="https://oss.yanxin7.cn/2023-08-11/dcd6fde971ec7294090f9c5ee5da7072"></el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="zhengshu">
          <div class="nav-frist2" style="margin-bottom: 5vw; padding-top: 3vw">
            <div>
              <h1 class="zhengshu-name">荣誉证书</h1>
            </div>
            <div class="b-line2" style="margin-bottom: 1vw"></div>
            <div style="color: white; margin-bottom: 5vw">
              195个证书，其中软件著作证书75项，商标证书56项，荣誉证书9项，国际顶级域名证书6项，体系3项，还有其他证书46项。
            </div>
            <div class="flex-row-between" style="margin-bottom: 3vw">
              <div v-for="(item, index) in certificateList1" :key="index + 9">
                <div>
                  <el-image style="width: 8vw; height: 6vw" :src="item.url"></el-image>
                </div>
                <div style="margin-top: 5px; color: white">{{ item.name }}</div>
              </div>
            </div>

            <div class="flex-row-between">
              <div v-for="(item, index) in certificateList2" :key="index + 9">
                <div>
                  <el-image style="width: 8vw; height: 6vw" :src="item.url"></el-image>
                </div>
                <div style="margin-top: 5px; color: white">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div>
            <el-image style="width: 90vw; height: 29vw"
              src="https://oss.yanxin7.cn/2023-08-11/08b712a732e736036761c31a00d1ee82"></el-image>
          </div>

          <div style="margin-top: 5vw">
            <el-image style="width: 62vw; height: 26vw" :src="zuzuoquan"></el-image>
          </div>
          <div style="color: white; margin-top: 1vw;font-weight:bolder;font-size: 20px;">软件著作权</div>
        </div>

        <div class="nav-frist" style="margin-top: 5vw">
          <div class="flex-cloumn-center">
            <h1 class="company-name">创始人故事</h1>
            <div class="b-line"></div>
          </div>
        </div>
        <div class="originator" style="margin-bottom: 10vw">
          <div class="originator-store">
            <video controls src="https://dev.yanxin7.cn/bjt.mov"></video>
          </div>
        </div>
      </div>
      <div class="flex-cloumn-center">
        <div class="nav-frist2" style="margin-top: 5vw">
          <div class="flex-cloumn-center">
            <h1 class="company-name">团队介绍</h1>
            <div class="b-line"></div>
          </div>
          <div class="member-list" style="margin-bottom:10px;">
            <div v-for="(item, index) in fristList" :key="index / 23">
              <el-image style="width:15vw;height:15vw" :src="item.url"></el-image>
            </div>
          </div>
          <div class="member-list" style="margin-bottom:10px;">
            <div v-for="(item, index) in fristList2" :key="index / 23">
              <el-image style="width:15vw;height:15vw" :src="item.url"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-cloumn-center" ref="me">
        <div class="nav-frist2" style="margin-top: 5vw">
          <div class="flex-cloumn-center">
            <h1 class="company-name">联系我们</h1>
            <div class="b-line"></div>
          </div>
        </div>
        <div class="map">
          <el-image src="https://oss.yanxin7.cn/2023-08-11/e39ec15a19472318605ba6c9858057b1"></el-image>
          <div class="adress">
            <el-image style="width:300px"
              src="https://oss.yanxin7.cn/2023-08-11/29b92dd83698a1fec77f5f48616b7ad3"></el-image>
          </div>

          <div class="contact">
            <div class="flex-row-start bd-b">
              <el-image style="width: 1.5vw;margin-top:-24px;height: 1vw;margin-right: 5px;"
                src="https://oss.yanxin7.cn/2023-08-11/9bbee204c0b31fffe2c9818430ff6ca4"></el-image>
              <div style="margin-top:-24px;" class="min-width90">运营地址：</div>
              <div>南宁市青秀区中柬路9号利海·亚洲国际5号楼5单元2101号</div>
            </div>
            <div class="flex-row-start mb-20">
              <el-image style="width: 1vw;height: 1vw;margin-right: 5px;"
                src="https://oss.yanxin7.cn/2023-08-11/6561bf2a8c791e1530cd14b7a7ec2725"></el-image>
              <div class="min-width90">加盟热线：</div>
              <div class="">0771-4985999</div>
            </div>
            <div class="flex-row-start ">
              <el-image style="width: 1vw;height: 1vw;margin-right: 5px;"
                src="https://oss.yanxin7.cn/2023-08-11/6561bf2a8c791e1530cd14b7a7ec2725"></el-image>
              <div class="min-width90">商务热线：</div>
              <div class="">13055539999</div>
            </div>
          </div>

        </div>
        <div style="height:100px;"></div>
      </div>
    </div>

    <login-form v-if="showLoginForm" :routeRedirect="routeRedirect" @loginSuccess="handleLoginSuccess"
      @close="handleLoginClose" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  urlCommonColumnList,
  urlSystemList,
  getCatory,
  getIndustryInformationPage,
  urlBrowseUserStatTheme1,
  urlBrowseRegUserStatTheme1,
} from "@/api/engineering";
import LoginForm from "@/components/login-form";
export default {
  data() {
    return {
      fristList: [
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/9e6692cd6ea96d486934155fd42d8b48'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/f9702283d024f9b856171cd728058881'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/9551c629bd508a278f8241c98aa095d4'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/a0171ba7c112e9588589108db1517830'
        }
      ],
      fristList2: [
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/6ff90f271275099ae3fcb503c4791a4c'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/f8edf9daf3b1c609202f6c8697828b4d'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/54c39fed4c3b0537731b717fcb9b8642'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/680ac2dcf5ab5ff8e0fed351c6b89bdb'
        }
      ],
      certificateList1: [
        {
          name: "作品登记证书",
          url: "https://oss.yanxin7.cn/image/2430092 (29).png",
        },
        {
          name: "出版物经营许可证",
          url: "https://oss.yanxin7.cn/image/9541822 (30).png",
        },
        {
          name: "人力资源许可证",
          url: "https://oss.yanxin7.cn/image/2635952 (31).png",
        },
        {
          name: "诚信经营示范单位",
          url: "https://oss.yanxin7.cn/image/6375392 (1).png",
        },
        {
          name: "广播电视许可证",
          url: "https://oss.yanxin7.cn/image/8165482 (2).png",
        },
        {
          name: "企业营业执照",
          url: "https://oss.yanxin7.cn/image/8658732 (3).png",
        },
      ],
      certificateList2: [
        {
          name: "企业认证",
          url: "https://oss.yanxin7.cn/image/8546312 (21).png",
        },
        {
          name: "ICP证书",
          url: "https://oss.yanxin7.cn/image/4088762 (24).png",
        },
        {
          name: "图形商标证书",
          url: "https://oss.yanxin7.cn/image/7652322 (25).png",
        },
        {
          name: "文字注册证书",
          url: "https://oss.yanxin7.cn/image/5707782 (26).png",
        },
        {
          name: "域名证书",
          url: "https://oss.yanxin7.cn/image/4009522 (27).png",
        },
        {
          name: "3C证书",
          url: "https://oss.yanxin7.cn/image/2537012 (28).png",
        },
      ],
      is3a: "https://oss.yanxin7.cn/image/9029672 (10).png",
      zuzuoquan: "https://oss.yanxin7.cn/image/1205891 (2).png",
      showLoginForm: false,
      companyList: [],
      videoUrlList: [],
      newsList: [],
      menuList: [
        { label: "首页", name: "home" },
        { label: "资质查询", name: "quation" },
        { label: "金融服务", name: "record" },
        { label: "招标投标", name: "personnel" },
        { label: "机械管理", name: "project" },
        { label: "造价咨询", name: "certificate" },
        { label: "智慧工地", name: "creditscore" },
      ],
      activeTab: "home",
      serviceList: [],
      currentIndex: 0,
      intervalId: null,
    };
  },
  created() { },
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    optionHover() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
  },
  components: {
    LoginForm,
  },
  mounted() {
    console.log('xxxx', this.$route.query.params)
    document.title = "联系我们";
    this.init();
    if (this.$route.query.params == 'product') {
      this.$refs.targetElement.scrollIntoView({ behavior: 'smooth' });
    }
    if (this.$route.query.params == 'contentMe') {
      this.$refs.me.scrollIntoView({ behavior: 'smooth' });
    }

  },
  methods: {

    handVideo(e) {
      console.log("eeee", e);
      console.log("xxx", this.$refs.viodes);
      if (this.$refs.viodes.length > 0) {
        for (let i = 0; i < this.$refs.viodes.length; i++) {
          this.$refs.viodes[i].pause();
        }
      }
      // this.$refs.viodes[0].pause()
    },

    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      // this.$router.replace(toPage);
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      this.activeTab = "home";
      if (this.redirectPath != "/") {
        this.redirectPath = "/";
        this.$router.push({ path: this.redirectPath });
      }
    },
    init() {
      this.geturlCommonColumnList();
      this.geturlSystemList();
      this.getCatory();
      this.getIndustryInformation();
      // this.getUrlBrowseUserStatTheme1()
      // this.getUrlBrowseRegUserStatTheme1()
    },
    // 获取访问量
    async getUrlBrowseUserStatTheme1() {
      try {
        const { data } = await urlBrowseRegUserStatTheme1({});
        console.log("xxxxsss", data);
      } catch (error) {
        console.log("urlBrowseRegUserStatTheme1", error);
      }
    },
    // 获取注册用户数
    async getUrlBrowseRegUserStatTheme1() {
      try {
        const { data } = await urlBrowseUserStatTheme1({});
        console.log("xxxxsss", data);
      } catch (error) {
        console.log("urlBrowseUserStatTheme1", error);
      }
    },
    async getIndustryInformation() {
      try {
        const { data } = await getIndustryInformationPage({
          // pageSize: 100,
          // pageNum: 1,
          current: 1,
          size: 100,
          industryInformationClassId: -1,
        });
        let records = data.records || [];
        records.forEach((element) => {
          let updateTime = element.updateTime || "";
          element.dateText = updateTime;
          if (updateTime) {
            element.dateText = updateTime.split(" ")[0];
          }
        });
        this.newsList = records;
        console.log("dasdadasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    toNewDetail(item) {
      var url = "";
      if (item.industryDetailsType == 1) {
        url = item.informationDetailsUrl;
      } else {
        let baseUrl = "https://api.yanxin7.cn/api";
        if (
          window.location.origin.includes("localhost") ||
          window.location.origin.includes("dev.yanxin7.cn")
        ) {
          // 在开发环境，用开发链接
          baseUrl = "https://dev.yanxin7.cn/api";
        }
        url = baseUrl + "/v1/industry/detail?industryId=" + item.informationId;
      }
      window.open(url);
    },
    handleTabClick(tab) {
      let token = this.$store.state.account.token;
      console.log("tap------", tab);
      if (tab.name == "quation") {
        this.toPath("/home/index");
      }
      if (tab.name == "certificate") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/engineering/index");
      }

      if (tab.name == "personnel") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/recruit/index");
      }
      if (tab.name == "record") {
         if (window.location.origin.includes("localhost")) {
          window.open("https://dev.yanxin7.cn" + "/bh_web/#/")
          return
        }
        window.open(window.location.origin + "/bh_web/#/")
        return
      }
      if (tab.name == "project") {
        const w = window.open("about:blank");
        // w.location.href = "https://www.bangjiantong.com/pc/mechanics.html";
        w.location.href = "https://trial.yanxin7.cn/erp/#/machine_login";
      }
      if (tab.name == "creditscore") {
        const w = window.open("about:blank");
        w.location.href = "https://trial.yanxin7.cn/erp/#/site_login";
      }
    },

    toEnterpriseService(routerPath = "/insurance/home/index") {
      const host = window.location.host;
      let targetUrlPrefix = "";
      // TODO: 测试环境条测试，正式跳正式
      if (
        host.includes("dev.yanxin7.cn") ||
        host.includes("localhost") ||
        host.includes("192.168")
      ) {
        targetUrlPrefix = "https://dev.yanxin7.cn" + "/bxbh/#";
      } else {
        targetUrlPrefix = window.location.origin + "/bxbh/#";
      }
      window.open(targetUrlPrefix + routerPath);
    },

    toPath(url) {
      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      console.log("vRouter", vRouter);
      window.open(vRouter.href, "_blank");
    },

    // 获取建筑行业服务列表
    async geturlCommonColumnList() {
      try {
        const { data } = await urlCommonColumnList({
          appid: "pcd87133b3134a7dff",
        });
        this.$nextTick(() => {
          this.serviceList = data;
        });
      } catch (error) {
        console.log("ee", error);
      }
    },

    async getCatory() {
      try {
        const { data } = await getCatory({ appid: "pcd87133b3134a7dff" });
        this.companyList = data.filter((item) => item.type == "home");
        console.log("data=xx==>", data);
      } catch (error) {
        console.log("ee", error);
      }
    },

    async geturlSystemList() {
      try {
        const { data } = await urlSystemList({ appid: "pcd87133b3134a7dff" });
        this.videoUrlList = data?.records;
      } catch (error) {
        console.log("videoUrl", error);
      }
    },

    handItem(item) {
      window.open(item.params);
    },
  },
};
</script>
<style>
.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}

.el-carousel__button {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
</style>
<style scoped lang="less">
/* 头部背景图 */
.search-for {
  width: 100%;
  height: 19vw !important;
  color: white;
  text-align: center;
  background-image: url("https://oss.yanxin7.cn/2023-08-15/d42ec178c8394c7041a57a65120dcbd1");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 1;
}

.el-tabs--border-card {
  background: transparent;
  box-shadow: none;
}

.nav-left {
  width: 1200px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: black;
}

.nav-width {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-frist {
  width: 62vw;
}

.b-line {
  margin-top: 10px;
  width: 2vw;
  border-bottom: 1px solid #2288fc;
  margin-bottom: 5vw;
}

.company-name {
  font-weight: bold;
  font-size: 32px;
}

.flex-row-center {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-cloumn-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  text-align: start;
  margin-bottom: 10px;
  line-height: 38px;
}

.nav-top {
  display: flex;
  padding-top: 5vw;
  justify-content: space-between;
  margin-bottom: 5vw;
}

.nav-flex {
  display: flex;
  align-items: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.c-8 {
  color: #888888;
}

.zhengshu {
  width: 100%;
  height: 103vw;
  background-color: #2c89f3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zhengshu-name {
  color: white;
  font-weight: bold;
  font-size: 32px;
}

.b-line2 {
  margin-top: 10px;
  width: 2vw;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 5vw;
}

.nav-frist2 {
  width: 62vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row-between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.originator {
  margin-top: 5vw;
  width: 100%;
  height: 21vw;
  background-image: url(https://oss.yanxin7.cn/2023-08-11/bca9bbe71fae8a86785748415028918b);
  background-repeat: no-repeat;
  position: relative;
}

.originator-store {
  width: 62vw;
  height: 35vw;
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  top: -35%;

  video {
    width: 100%;
    height: 100%;
  }
}

.member {}

.map {
  position: relative;
  height: 31vw;
}

.adress {
  position: absolute;
  top: 32%;
  left: 18%;
}

.contact {
  text-align: start;
  top: 135px;
  right: 165px;
  position: absolute;
  width: 29vw;
  height: 270px;
  background-color: #FFFFFF;
  box-shadow: 0 0 1px 1px rgb(223, 222, 222);
  border-radius: 16px;
  overflow: hidden;
  z-index: 5;
  padding: 60px;
  font-size: 18px;
  color: #333E4D;
}

.bd-b {
  border-bottom: 1px solid rgb(209, 207, 207);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.flex-row-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.min-width90 {
  min-width: 90px;
}

.mb-20 {
  margin-bottom: 20px;
}

.member-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
}</style>
